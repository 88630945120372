import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { formatTimeInput } from '../utils/DateUtils';
import { StyleContextType } from '../@types/style.d';
import { useStyleContext, styleModes } from '../contexts/style-context';

interface Props {
  countdownApiRef: React.RefObject<Countdown>
  time: number
  setTime: React.Dispatch<React.SetStateAction<number>>
  timeDelta: CountdownTimeDelta
  setAutoStart: React.Dispatch<React.SetStateAction<boolean>>
  message: string
  setMessage: React.Dispatch<React.SetStateAction<string>>
  isCounting: boolean
  setIsCounting: React.Dispatch<React.SetStateAction<boolean>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Form: React.FC<Props> = (props) => {
  const {
    countdownApiRef,
    time,
    setTime,
    timeDelta,
    setAutoStart,
    message,
    setMessage,
    isCounting,
    setIsCounting,
    setOpen,
  } = props;

  const { setStyle } = useStyleContext() as StyleContextType;
  const [timeInput, setTimeInput] = React.useState(formatTimeInput(time));

  const handleStartClick = () => {
    setAutoStart(true);
    setIsCounting(true);
    countdownApiRef?.current?.start();
    setOpen(false);
  };

  const handlePauseClick = () => {
    setAutoStart(false);
    setIsCounting(false)
    countdownApiRef?.current?.pause();
  };

  const handleResetClick = () => {
    setAutoStart(false);
    setIsCounting(false)
    countdownApiRef?.current?.pause();
    setTime(Date.now());
  };

  const handleTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const now = new Date();
    const hours = parseInt(event.target.value.split(':')[0]);
    const minutes = parseInt(event.target.value.split(':')[1]);
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();
    let timeObj = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds, milliseconds);
    if (timeObj.getTime() < Date.now()) {
      timeObj = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, hours, minutes, seconds, milliseconds);
    }
    
    setTimeInput(event.target.value);
    setTime(timeObj.getTime());
  }

  const handleQuickTimeChange = (additionalTime: number) => {
    const timeObj = new Date(Date.now() + timeDelta.total + additionalTime);
    setTime(timeObj.getTime());
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }

  const handleStyleChange = (event: SelectChangeEvent<number>) => {
    setStyle(styleModes[Number(event.target.value)]);
  }

  return (
    <Box>
      <Box sx={{ m: 2 }}>
        <TextField
          id="time"
          label="Time"
          type="time"
          size="small"
          fullWidth
          margin="dense"
          value={timeInput}
          onChange={handleTimeInputChange}
        />
      </Box>
      <Box sx={{ m: 2 }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {[5, 10, 30].map((secs, index) => (
            <Grid item xs={4} key={secs}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => {
                  handleQuickTimeChange(secs * 1000)
                }}>
                + {secs} secs
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ m: 2 }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {[1, 2, 3, 5, 10, 30].map((mins, index) => (
            <Grid item xs={4} key={mins}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => {
                  handleQuickTimeChange(mins * 60 * 1000)
                }}>
                + {mins} mins
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ m: 2 }}>
        <TextField
          id="message"
          label="Message"
          size="small"
          fullWidth
          margin="dense"
          multiline
          rows={3}
          value={message}
          onChange={handleMessageChange}
        />
      </Box>
      <Divider />
      <Box sx={{ m: 2}}>
        <FormControl
          size="small"
          fullWidth>
          <InputLabel id="demo-simple-select-label">Background</InputLabel>
          <Select
            labelId="animation"
            id="animation-select"
            defaultValue={0}
            label="Background"
            onChange={handleStyleChange}
          >
            {Object.entries(styleModes).map(([key, value]) =>
              <MenuItem key={key} value={key}>{value.label}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Box sx={{ m: 2 }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              onClick={handleResetClick}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: !isCounting ? 'block': 'none' }}>
            <Button
              type="button"
              variant="contained"
              fullWidth
              onClick={handleStartClick}
              disabled={isCounting || timeDelta.total === 0}
            >
              Start
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: isCounting ? 'block': 'none' }}>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              onClick={handlePauseClick}
              disabled={!isCounting}
            >
              Pause
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Form;
