import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Main from './components/Main';
import Form from './components/Form';
import { formatTime } from './utils/DateUtils';
import { StyleProvider } from './contexts/style-context';

function App() {
  const drawerWidth = 420;
  const [open, setOpen] = React.useState(true);
  const countdownApiRef = React.useRef() as React.RefObject<Countdown>;
  const [time, setTime] = React.useState(new Date(Date.now() + 10 * 60 * 1000).getTime());
  const [timeDelta, setTimeDelta] = React.useState<CountdownTimeDelta>({
    completed: false,
    days: 0,
    hours: 0,
    milliseconds: 0,
    minutes: 0,
    seconds: 0,
    total: 0,
  });
  const [autoStart, setAutoStart] = React.useState(false);
  const [message, setMessage] = React.useState('A break will be finishing at {time}');
  const [isCounting, setIsCounting] = React.useState(false);

  const handleTimeDelta = (timeDelta: CountdownTimeDelta) => {
    setTimeDelta(timeDelta);
  }

  return (
    <StyleProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          open={open}
          setOpen={setOpen}
          drawerWidth={drawerWidth}
        />
        <Drawer
          open={open}
          setOpen={setOpen}
          drawerWidth={drawerWidth}
        >
          <Form
            countdownApiRef={countdownApiRef}
            time={time}
            setTime={setTime}
            timeDelta={timeDelta}
            setAutoStart={setAutoStart}
            message={message}
            setMessage={setMessage}
            setOpen={setOpen}
            isCounting={isCounting}
            setIsCounting={setIsCounting}
          />
        </Drawer>
        <Main
          open={open}
          drawerWidth={drawerWidth}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{ textAlign: 'center' }}
          >
            <Grid item xs={12}>
              <Box sx={{ p: 3, fontSize: '10vw', fontWeight: '100' }}>
                <Countdown
                  key={time}
                  date={time}
                  ref={countdownApiRef}
                  autoStart={autoStart}
                  daysInHours={true}
                  onMount={handleTimeDelta}
                  onStart={handleTimeDelta}
                  onPause={handleTimeDelta}
                  onStop={handleTimeDelta}
                  onTick={handleTimeDelta}
                  onComplete={handleTimeDelta}
                />
              </Box>
              <Box sx={{ p: 3, fontSize: '2vw', whiteSpace: 'pre-line' }}>
                {message.replaceAll('{time}', formatTime(new Date(time)))}
              </Box>
            </Grid>
          </Grid>
        </Main>
      </Box>
    </StyleProvider>
  );
}

export default App;
