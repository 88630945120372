export const formatTime = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minsStr = minutes < 10 ? '0' + minutes : minutes;
  let timeStr = hours + ':' + minsStr + ' ' + ampm;
  return timeStr;
}

export const formatTimeInput = (timestamp: number) => {
  const dateObj = new Date(timestamp);
  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  let minsStr = minutes < 10 ? '0' + minutes : minutes;
  let timeStr = hours + ':' + minsStr;
  return timeStr;
}