import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { IStyleMode, StyleContextType } from '../@types/style.d';

const styleModes: IStyleMode[] = [
  {
    mode: 'dark',
    css: 'gradient',
    label: 'Gradient (Animation)',
  },
  {
    mode: 'dark',
    css: 'gradient paused',
    label: 'Gradient',
  },
  {
    mode: 'dark',
    css: 'dark',
    label: 'Dark',
  },
  {
    mode: 'light',
    css: 'light',
    label: 'Light',
  },
]

const StyleContext = React.createContext<StyleContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const StyleProvider: React.FC<Props> = ({ children }) => {
  const [style, setStyle] = React.useState<IStyleMode>(styleModes[0]);
  const theme = createTheme({
    palette: {
      mode: 'dark', // 'light' or 'dark'
    },
  });

  return (
    <StyleContext.Provider value={{style, setStyle}}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyleContext.Provider>
  )
}

const useStyleContext = () => React.useContext(StyleContext)

export { StyleProvider, useStyleContext, styleModes }