import React from 'react';
import fscreen from 'fscreen';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { StyleContextType } from '../@types/style.d';
import { useStyleContext } from '../contexts/style-context';

const styles: { [id: string]: React.CSSProperties } = {
  light: {
    color: "black"
  },
  dark: {
    color: "white",
  }
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerwidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    marginLeft: `${drawerwidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerWidth: number;
}

const MyAppBar: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    drawerWidth,
  } = props;
  const { style } = useStyleContext() as StyleContextType;
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleFullscreen = () => {
    var isInFullScreen = (fscreen.fullscreenElement && fscreen.fullscreenElement !== null);
    var docElm = document.documentElement;
    if (!isInFullScreen) {
      setIsFullscreen(true);
      fscreen.requestFullscreen(docElm);
    } else {
      setIsFullscreen(false);
      fscreen.exitFullscreen();
    }
  }

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      open={open}
      drawerwidth={drawerWidth}>
      <Toolbar>
        <Tooltip title="Menu">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon style={styles[style.mode]} />
          </IconButton>
        </Tooltip>
        <Typography
          noWrap
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, visibility: 'hidden' }}>
          Break Timer
        </Typography>
        <Tooltip title="Fullscreen">
          <IconButton
            style={styles[style.mode]}
            onClick={handleFullscreen}
            sx={{ ml: 2 }}
          >
            { !isFullscreen ? <FullscreenIcon /> : <FullscreenExitIcon /> }
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

export default MyAppBar;