import React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { StyleContextType } from '../@types/style.d';
import { useStyleContext } from '../contexts/style-context';
import '../App.css';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  drawerwidth?: number;
  open?: boolean;
}>(({ theme, drawerwidth, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerwidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface Props {
  open: boolean;
  drawerWidth: number;
  children: React.ReactNode;
}

const MyMain: React.FC<Props> = (props) => {
  const {
    open,
    drawerWidth,
    children,
  } = props;
  const { style } = useStyleContext() as StyleContextType;

  return (
    <Main open={open} drawerwidth={drawerWidth} className={style.css}>
      <Container className="App" disableGutters={true} maxWidth={false}>
        {children}
      </Container>
    </Main>
  )
};

export default MyMain;